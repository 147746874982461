<template>
<section class="perfil">
    <header>
        <img :src=me alt="Vinícius Martins">
        <h1>Muito Prazer,</h1><br/>
        <p>meu nome é <strong>Vinícius Martins Pedro</strong>, Desenvolvedor de Software, uma pessoa apaixonada que sempre busca trabalhar em produtos
            completos, desenvolvendo sistemas sociais e técnicos sustentáveis e
            escaláveis para criar impacto.</p>
    </header>
    <footer>
        <a :href=git target="_blank" aria-label="gitHub">
            <mdicon name="github" size="40" class="icon-github"/>
        </a>
        <a :href=mail target="_blank" aria-label="email">
            <mdicon name="email" size="40" class="icon-email"/> 
        </a>
        <a :href=zap target="_blank" aria-label="whatsapp">
            <mdicon name="whatsapp" size="40" class="icon-zap"/>
        </a>
        <a :href=linkdin target="_blank" aria-label="linkdin">
            <mdicon name="linkedin" size="40" class="icon-link"/>
        </a>
    </footer>
</section>
</template>
<script src="./index.js"/>
<style src="./styles.scss" lang="scss" scoped/>