<template>
<section class="description">
    <fieldset class="description-fieldset">
        <h1>Um pouco sobre mim,</h1>
        <p>
            Sou apaixonado por resolver problemas complexos e tenho habilidades analíticas afiadas. Sou autodidata e estou constantemente estudando e praticando para aprimorar minhas habilidades de programação.

Possuo habilidades de comunicação eficazes e gosto de trabalhar em equipe para alcançar metas comuns. Sou uma pessoa dedicada, motivada e sempre em busca de novos desafios.
        </p>
    </fieldset>
    <fieldset class="description-fieldset">
        <h1>Skills</h1>
        <div class="description-fieldset-icons">           
            
            <span >
                <h5>Javascript</h5>
                <img :src=javascript alt="icon javascript">
            </span>
            <span >
                <h5>Typescript</h5>
                <img :src=typescript alt="icon typescript">
            </span>
            <span >
                <h5>Vue.js</h5>
                <img :src=vue alt="icon vue">
            </span>
            <span >
                <h5>React.js</h5>
                <img :src=react alt="icon react">
            </span>
            <span >
                <h5>Wordpress</h5>
                <img :src=wordpress alt="icon wordpress">
            </span>
            <span >
                <h5>Git</h5>
                <img :src=git alt="icon git">
            </span>
            <span >
                <h5>Docker</h5>
                <img :src=docker alt="icon docker">
            </span>
        </div>
    </fieldset>
    <fieldset class="description-fieldset">
        <h1>Meus projetos</h1>
        <div class="container-modal">
            <div class="modal">
                <a :href=linkSchmidt target="_blank">
                    <img :src=schmidt alt="Imagem projeto Crud">
                </a>
                <p>
                    <strong>Schmidt & Martins</strong> este é um portfolio construído para a empresa Schmidt & Martins, visando dar mais transparência e visibilidade para a empresa. Desenvolvido em Wordpres, com o plugin Elementor.
                </p>
            </div>
            <div class="modal">
                <a :href=linkTools target="_blank">
                    <img :src=tools alt="Imagem projeto Tools">
                </a>
                <p id="para">
                    <strong>Very Useful Tools To Remember:</strong> uma API construída utilizando TDD e documentada com SWAGGER,
                    desenvolvida em Node e NestJs.
                </p>
            </div>
            <div class="modal">
                <a :href=linkEcoleta target="_blank">
                    <img :src=ecoleta alt="Imagem projeto Ecoleta">
                </a>
                <p id="para">
                    <strong>Ecoleta:</strong> aplicação de coleta de resíduos,
                    desenvolvida em Typescript, React, React Native, Node e Sqlite.
                </p>
            </div>
            <div class="modal">
                <a :href=linkProffy target="_blank">
                    <img :src=proffy alt="Imagem projeto Proffy">
                </a>
                <p>
                    <strong>Proffy:</strong> plataforma de procura de professores,
                    desenvolvida em React, Node, Typescript e Sqlite.
                </p>
            </div>
            <div class="modal">
                <a :href=linkViaCep target="_blank">
                    <img :src=viaCep alt="Imagem projeto ViaCep">
                </a>
                <p>
                    <strong>ViaCep:</strong> aplicação consumindo a api do viaCep,
                    desenvolvida usando Vue e Axios.
                </p>
            </div>
            <div class="modal">
                <a :href=linkStarBucks target="_blank">
                    <img :src=starBucks alt="Imagem projeto Clone Star bucks">
                </a>
                <p>
                    <strong>Clone Star Bucks:</strong> Essa aplicação é um clone do site do StarBucks,
                    feito em HTML5 e CSS3, usando CSS grid.
                </p>
            </div>
            
            <div class="modal" >
                <a :href=linkAngular target="_blank">
                    <img :src=angular alt="Imagem projeto firebase">
                </a>
                <p>
                    <strong>Firebase-CRUD:</strong> aplicação simples CRUD, visando testar a integração entre Firebase,
                    desenvolvido em Angular como desafio.
                </p>
            </div>
            <div class="modal" >
                <a :href=linkCurrent target="_blank">
                    <img :src=current alt="Imagem projeto previsão do tempo">
                </a>
                <p>
                    <strong>Previsão do tempo:</strong> aplicação de previsão do tempo, utilizando a api Open Weather,
                    desenvolvido em Vue 3.
                </p>
            </div>
        </div>
    </fieldset>
</section>
</template>
<script src="./index.js"/>
<style src="./styles.scss" lang="scss" scoped/>