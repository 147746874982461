import me from '../../assets/imgs/profile-pic.png'

export default {
    data() {
        return {
            me,
            git: 'https://github.com/viniciusmartinsdev',
            linkdin: 'https://www.linkedin.com/in/viniciusmartinsdev',
            zap: 'https://wa.me/55988409928',
            mail: 'mailto:vinicius.martins.developer@gmail.com?subject=Contato'
        }
    },
    methods: {
      
      
    },
}