import { ref } from 'vue';
import html from '../../assets/icons/icon-html-5.svg';
import css from '../../assets/icons/icon-css3.svg';
import sass from '../../assets/icons/icon-sass.svg';
import javascript from '../../assets/icons/icon-javascript.svg';
import typescript from '../../assets/icons/icon-typescript.svg';
import vue from '../../assets/icons/icon-vue-js.svg';
import react from '../../assets/icons/icon-react.svg';
import git from '../../assets/icons/icon-git.svg';
import ecoleta from '../../assets/imgs/ecoleta.png';
import proffy from '../../assets/imgs/proffy.png';
import viaCep from '../../assets/imgs/viaCep.png';
import starBucks from '../../assets/imgs/star1.png';
import schmidt from '../../assets/imgs/schmidt.png';
import angular from '../../assets/imgs/angular.png';
import current from '../../assets/imgs/current-weather-resized.png';
import docker from '../../assets/icons/icon-docker.svg';
import wordpress from '../../assets/icons/wordpress.svg'
import tools from '../../assets/imgs/tools.png'

export default {
    setup() {
        const linkAngular = ref('https://schmidt-angular-firebase-exercise.netlify.app/');
        const linkSchmidt = ref('https://schmidtmartins.com/');
        const linkViaCep = ref('https://viacep-vue.netlify.app/');
        const linkStarBucks = ref('https://clone-star-bucks.netlify.app/');
        const linkProffy = ref('https://github.com/robovmp/next-level-week-02');
        const linkEcoleta = ref('https://github.com/robovmp/next-level-week-01');
        const linkCurrent = ref('https://currentopenweather.netlify.app/');
        const linkTools = ref('https://github.com/viniciusMartinsDev/very-useful-tools-to-remember');

        return {
            html,
            css,
            sass,
            javascript,
            typescript,
            vue,
            git,
            react,
            ecoleta,
            proffy,
            viaCep,
            starBucks,
            angular,
            schmidt,
            current,
            docker,
            wordpress,
            tools,
            linkAngular,
            linkSchmidt,
            linkViaCep,
            linkStarBucks,
            linkProffy,
            linkEcoleta,
            linkCurrent,
            linkTools
        };
    },
    methods: {
    },
};
