<template id="app">
  <PerfilComponent/>
  <DescriptionComponent/>
</template>

<script>
// import back from './assets/imgs/back.jpg'


import PerfilComponent from './components/PerfilComponent/PerfilComponent.vue'
import DescriptionComponent from './components/DescriptionComponent/DescriptionComponent.vue'

export default {
  name: 'App',
  components: {
    PerfilComponent,
    DescriptionComponent
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
